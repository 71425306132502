.new-footer {
  padding: 50px 0 30px;
  background-color: $creamColor;

  @media #{$mq-max-md} {
    padding-top: 40px;
  }

  .footer-links-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    @media #{$mq-max-md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 15px;
      width: 85%;

      .logo-container {
        grid-column: span 2;
        margin-bottom: 20px;
      }

      .order-1 {
        order: 1;
      }
      .order-2 {
        order: 2;
      }
      .order-3{
        order: 3;
      }
      .order-4 {
        order: 4;
      }
    }

    .footer-column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;

      @media #{$mq-max-md} {
        gap: 15px;
      }
    }
  }

  .bottom-footer {
    width: 90%;
    margin: 40px auto 0;
    border-top: 1px solid $darkColor;
    display: flex;
    gap: 100px;
    justify-content: center;
    padding-top: 40px;

    @media #{$mq-max-md} {
      width: 85%;
      display: grid;
      gap: 0;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .footer-link {
    color: $darkColor;
    text-decoration: none;
    font-size: $fs-sm;
    @include font-poppins(300);

    &:hover {
      text-decoration: underline;
    }
    @media #{$mq-max-md} {
      font-size: 13px;
    }
  }
}
