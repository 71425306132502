// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.green-button {
  display: inline-flex;
  padding: 12px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #C9D5C8;

  a {
    text-decoration: none !important;
  }

  span {
    color: #36443A;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &:hover {
    box-shadow: 0 0 0 1.5px $darkColor !important;
    background-color: $darkColor;

    span {
      color: #FFFBF5;
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.white-button {
  font-size: 16px;
  line-height: 24px;
  background-color: #FFFBF5;
  color: #36443a;
  border-radius: 5px;
  padding: 12px 35px !important;

  a {
    color: #36443a;
    text-decoration: none !important;
  }

  &:hover {
    box-shadow: 0 0 0 1.5px $darkColor !important;
    background-color: $darkColor;

    span {
      color: #FFFBF5;
    }
  }
}

.dark-button {
  font-size: 16px;
  line-height: 24px;
  background-color: #36443A;
  color: #C9D5C8;
  border-radius: 5px;
  padding: 12px 35px !important;

  span {
    color: #C9D5C8
  }
}

.light-button {
  font-size: 16px;
  line-height: 24px;
  background-color: $creamColor;
  color: $darkColor;
  border-radius: 5px;
  padding: 12px 35px;
  text-decoration: none;

  &:hover {
    background-color: $darkColor;
    color: $creamColor;
  }
}

.capitalize {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
  text-transform: capitalize;
}

.uppercase {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
  text-transform: uppercase;
}

.press {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  .wsj {
    width: 42.412px;
    height: 22px;
    flex-shrink: 0;
  }

  .time {
    width: 66px;
    flex-shrink: 0;
  }

  .rollingstone {
    width: 101.624px;
    flex-shrink: 0;
  }

  .forbes {
    width: 66px;
    flex-shrink: 0;
  }

  .womenshealth {
    width: 110px;
    flex-shrink: 0;
  }

  .inc {
    width: 61px;
    height: 22px;
    flex-shrink: 0;
  }

  .otherimg {
    width: 100px;
    flex-shrink: 0;
  }
}

.slider-header {
  justify-content: flex-start;
  margin-top: 30px;

  @media screen and (min-width: $on-medium) {
    justify-content: center;
  }
}

.slider-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
  column-gap: 25px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;

  .slider-container {
    display: flex;
    flex-direction: column;
    scroll-snap-align: center;

    .slider-title {
      cursor: pointer;
      color: #36443A;
      text-align: center;
      font-size: 28px;
      line-height: normal;
      width: max-content;
      opacity: 0.5;
      @include font-larken(400);
    }

    .product-item {
      background-color: #fff;
      display: flex;
      width: 220px;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      min-height: 358px;

      .product-detail-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        text-align: center;
        justify-content: space-between;
      }

      .product-image {
        height: 190px;
        width: 220px;
        background: #36443A;
        object-fit: cover;
      }

      .object-contain {
        object-fit: contain;
      }

      .shrubs {
        object-position: 0 -85px;
        object-fit: cover;
      }
      .big-jar {
        object-position: 0 80%;
      }

      .object-cover {
        object-position: cover;
      }

      .small-jar {
        object-position: bottom;
      }

      .product-title {
        width: 65%;
        color: #36443A;
        text-align: center;
        font-size: 24px;
        line-height: 23px;
        margin: 12px;
        @include font-larken(400);
      }

      .food-name {
        margin: 0;
        color: #36443A;
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        padding-left: 15px;
        padding-right: 15px;
        @include font-poppins(400);
      }

      .nutrition {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-around;
        width: 100%;
        padding-bottom: 10px;
        align-items: flex-end;

        p {
          margin: 0;
          color: #36443A;
          text-align: center;
          font-size: 11px;
          line-height: 17px;
          @include font-poppins(400);
        }
      }
    }
  }

  .slider-container:first-child {
    margin-left: 20%;
  }

  .slider-container:last-child {
    margin-right: 20%;
  }
}

.testimonial-section {
  background-color: #fafafa;

  &__inner {
    display: flex;
    justify-content: space-between;

    .food-items {
      flex-basis: 50%;
      background-repeat: no-repeat;
      background-position: center;
      object-position: center;
      overflow: hidden;

      video {
        position: relative;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -48%);
      }
    }

    .sixth {
      flex-basis: 50%;
      background-color: #fafafa;
      overflow: hidden;
      margin: 0 auto;

      .title {
        font-size: 18px;
        line-height: 20px;
        color: $darkColor;
        text-align: center;
        text-transform: uppercase;
      }

      &__testimonials {
        &-control {
          width: 73%;
          padding-left: 12.5%;
          top: calc(16%);

          svg {
            width: 18px;
            cursor: pointer;
          }
        }

        &-dots {
          margin-top: 30px;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;

          div {
            display: block;
            width: 16px;
          }
        }
      }

      &__testimonial {
        &-image {
          img {
            width: 120px;
            height: 120px;
          }
        }
      }

      .sqs-block-content {
        .testimonial-text {
          width: 72.5%;
          margin: 0 auto;
          text-align: center;
          font-size: 28px !important;
          line-height: 34px !important;
          color: $darkColor;
          @include font-larken(300);

          &::after {
            content: "_";
            display: block;
            margin: 4px auto 26px auto;
            font-size: 50px;
            color: $darkColor;
            font-weight: 200;
          }
        }

        .author-name {
          font-size: 15px;
          line-height: 23px;
          margin: 0;
          padding: 0;
          padding-bottom: 4px;
          color: $darkColor;
          letter-spacing: 0.15px;
          @include font-larken(400);
        }

        .author-role {
          font-size: 15px;
          line-height: 23px;
          margin: 0;
          padding: 0;
          color: $darkColor;
          letter-spacing: 0.15px;
          @include font-larken(400);
        }
      }
    }
  }
}

.testimonial-section-dark {
  &__inner {
    display: flex;
    justify-content: space-between;

    .food-items {
      flex-basis: 50%;
      background-repeat: no-repeat;
      background-position: center;
      object-position: center;
      overflow: hidden;

      video {
        position: relative;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -48%);
      }
    }

    .sixth {
      flex-basis: 50%;
      background-color: #36443A;
      overflow: hidden;
      margin: 0 auto;

      .title {
        font-size: 18px;
        line-height: 20px;
        color: $darkColor;
        text-align: center;
        text-transform: uppercase;
      }

      &__testimonials {
        &-control {
          width: 73%;
          padding-left: 12.5%;
          top: calc(16%);

          svg {
            width: 18px;
            cursor: pointer;
          }
        }

        &-dots {
          margin-top: 30px;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;

          div {
            display: block;
            width: 16px;
          }
        }
      }

      &__testimonial {
        &-image {
          img {
            width: 120px;
            height: 120px;
          }
        }
      }

      .sqs-block-content {
        .testimonial-text {
          width: 70%;
          margin: 0 auto;
          text-align: center;
          font-size: 12px !important;
          line-height: 23px !important;
          color: #fff;
          margin-top: 24px;
          @include font-poppins(400);

          &::after {
            content: "_";
            display: block;
            margin: 4px auto 26px auto;
            font-size: 50px;
            color: $darkColor;
            font-weight: 200;
          }
        }

        .author-name {
          font-size: 15px;
          line-height: 23px;
          margin: 0;
          padding: 0;
          padding-bottom: 4px;
          color: #fff;
          letter-spacing: 0.15px;
          @include font-larken(400);
        }

        .author-role {
          font-size: 15px;
          line-height: 23px;
          margin: 0;
          padding: 0;
          color: #fff;
          letter-spacing: 0.15px;
          @include font-larken(400);
        }
      }
    }
  }
}

.item-title-dark {
  color: #FFFBF5;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0.15px;
  padding-left: 40px;
  padding-right: 40px;
  @include font-larken(400);
  font-size: 18px;

  @media #{$mq-max-md} {
    font-size: 15px;
  }
}

.item-content-dark {
  color: #FFFBF5;
  text-align: center;
  font-size: 12px;
  line-height: 23px;
  padding-left: 40px;
  padding-right: 40px;
  @include font-poppins(200);
  @media screen and (min-width: $screen-md) {
    font-size: $fs-sm;
  }
}

.item-content-white {
  color: #36443A;
  text-align: center;
  font-size: 12px;
  line-height: 23px;
  @include font-poppins(200);
  @media screen and (min-width: $screen-md) {
    font-size: $fs-sm;
  }
}

.item-title-white {
  color: #36443A;
  font-size: 18px;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0.15px;
  @include font-larken(400);

  @media #{$mq-max-md} {
    font-size: 15px;
  }
}

.fade-in-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.navbar {
  display: block;

  .green-button-style {
    height: 20px;
  }

  .new-green-button {
    padding: 12px 35px !important;
    border-radius: 5px;
    background-color: $lightGreenColor;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      background-color: $darkColor;
      color: $creamColor;
    }
  }
}

.navbar a {
  text-decoration: none;
  color: $darkColor;
  padding: 1rem;
  transition: color 0.3s;
  @include font-poppins(400)
}

.navbar a:hover {
  text-decoration: underline;
}


.menu-item {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #36443A;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0;
  width: 100%;
}

.dropdown-content a {
  color: #FFFBF5;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: max-content;
  @include font-poppins(300);
  letter-spacing: 0.8px;
}

.dropdown-content a:hover {
  text-decoration: underline;
  transition: all 0.5s ease;
}

.menu-item:hover .dropdown-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}


// Display properties

.hidden-for-mobile {
  @media screen and (max-width: $screen-md) {
    display: none !important;
  }
}

.hidden-for-desktop {
  @media screen and (min-width: $screen-md) {
    display: none !important;
  }
}


.menu-message-text {
  font-family: "Larken", "Albra", sans-serif !important;
  font-size: x-large;
  text-align: center;
}

.menu-zip-code-input {
  padding: 10px 16px;
  align-self: center;
  display: inline-flex;
  width: 50%;
  font-size: 16px;
  font-family: "Poppins", "Helvetica Neue", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
}

.zip-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media #{$mq-max-md} {
    gap: unset;
    justify-content: space-between;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-image: url("https://d1cbtwb0qnacuk.cloudfront.net/images/light-box-image.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #c1d3c1;
  background-position-y: 20%;
  margin: 15% auto;
  padding: 20px;
  padding-bottom: 40px;
  border: 1px solid #888;
  width: 50%;
  max-width: 600px;
  height: 565px;

  @include media-query($on-palm) {
    width: fit-content;
    margin: unset;
    background-position-y: 100px;
  }
}

.model-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.main-container__marquee {
  z-index: 20;
  background-color: $creamColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  text-transform: uppercase;
}

.main-container__marquee-track {
  display: flex;
  gap: 1.25rem;
  white-space: nowrap;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  width: 100%;
}

.main-container__marquee-items {
  flex-shrink: 0;
  display: flex;
  gap: 1.25rem;
  justify-content: space-around;
  min-width: max-content;
  transition: transform 0.01s linear;
}

.main-container__marquee-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.5rem;
  transition: all 0.1s ease-in-out;

  img {
    max-width: 9.375rem;
    filter: grayscale(100%);
    transition: filter 0.3s ease;

    &:hover {
      filter: none;
    }
  }
}



// BUTTONS
.button-lightgreen-hover-cream {
  padding: 12px 35px;
  background-color: $lightGreenColor;
  color: $darkColor;
  border-radius: 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  text-align: center;
  @include font-poppins(400);

  &:hover {
    background-color: $creamColor;
  }
}

.button-lightgreen-hover-dark {
  padding: 12px 35px;
  background-color: $lightGreenColor;
  color: $darkColor;
  border-radius: 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  @include font-poppins(400);

  &:hover {
    background-color: $darkColor;
    color: $creamColor;
  }
}

.button-cream-hover-dark {
  padding: 12px 35px;
  background-color: $creamColor;
  color: $darkColor;
  border-radius: 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  @include font-poppins(400);

  &:hover {
    background-color: $darkColor;
    color: $creamColor;
  }
}

.button-dark-hover-cream {
  padding: 12px 35px;
  background-color: $darkColor;
  color: $creamColor;
  border-radius: 5px;
  text-decoration: none;
  letter-spacing: 1.5px;
  @include font-poppins(400);

  &:hover {
    background-color: $creamColor;
    color: $darkColor;
  }
}

//Links styles
.green-link {
  color: $darkColor;
  text-decoration: underline;
}

//DISABLED STYLE FOR ANCHOR BUTTONS
a {
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;

    &:hover {
      background-color: inherit;
      color: inherit;
      cursor: not-allowed;
    }
  }
}


// Fonts classes
.large-heading {
  font-size: $fs-xxl; //42px
  @include font-larken(400);
  color: $darkColor;
  @media #{$mq-max-md} {
    font-size: $fs-xl;
  }
}

.sm-heading {
  font-size: 1.5rem; //24px
  @include font-larken(400);
  color: $darkColor;

  @media #{$mq-max-md} {
    font-size: 22px;
  }
}

.md-para {
  font-size: $fs-sm; //15px
  @include font-poppins(200);
  color: $darkColor;

  @media #{$mq-max-md} {
    font-size: 14px;
  }
}
