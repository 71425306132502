.new-header {
  .banner {
    padding: 10px 0;
    color: $darkColor;
    position: fixed;
  }
}

/** Site banner **/

.green-back {
  padding: 0px;
  background-color: #a7c194;
  text-align: center;
  color: $font-color;
  text-transform: uppercase;
  @media #{$mq-max-md} {
    padding: 10px;
  }
  p {
    margin-bottom: 11px;
    @media #{$mq-max-md} {
      font-size: $fs-sm;
      margin-bottom: 0;
    }
  }
}

.pink-back {
  background-color: $coralColor;
  text-align: center;
  color: $darkColor;
  text-transform: uppercase;

  @media #{$mq-max-md} {
    padding: 10px;
  }

  p {
    margin-bottom: 11px;
    @media #{$mq-max-md} {
      font-size: $fs-sm;
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;
      color: $darkColor;
    }
  }
}

/**
 * Site header
 */
.site-header {
  // border-top: 5px solid $border-color-03;
  line-height: $base-line-height * $base-font-size * 2.25;
  background-color: $creamColor;
  position: fixed;
  z-index: 1000;
  width: 100%;
  @include media-query($screen-md) {
    transition: transform 0.3s ease-in-out;
  }

  @media #{$mq-max-md} {
    line-height: 1;
  }
}

#site-header.hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  @media #{$mq-max-md} {
    display: flex;
    align-items: center;
  }
}

.methodology-logo {
  max-width: unset;
  @include media-query($screen-md) {
    margin: 0 auto;
    // width: 133px;
    // height: 22px;
  }

  @media screen and (min-width: 915px) {
    content: url("../../assets/desktop-logo.svg");
  }
}

.user-login {
  @include media-query($screen-md) {
    width: fit-content;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    a {
      text-transform: capitalize;
      color: #36443A;
      font-size: 12px;
      margin-bottom: 0px;
      @include font-poppins(400);

      &:hover {
        text-decoration: underline;
      }
    }
    img {
      width: 22px;
      height: 23px;
      margin-left: 5px;
    }
  }
  @media screen and (min-width: $screen-md) {
    display: none;
  }
}

.user-login-desktop {
  width: fit-content;
  height: 69px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  p {
    text-transform: capitalize;
    color: #36443A;
    font-size: 16px;
    @include font-poppins(400);
  }
  span {
    margin-top: -3px;
  }
  img {
    width: 22px;
    height: 23px;
    margin-left: 5px;
    margin-top: 1px;
  }
  @include media-query($screen-md) {
    display: none;
  }
}

.site-nav {
  background-color: $creamColor;
  text-align: right;
  z-index: 1;
  padding-left: 5px;
  display: flex;
  align-items: center;

  @media screen and (min-width: $screen-md) {
    display: none;
  }

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;
  }

  label[for="nav-trigger"], #nav-close {
    display: block;
    float: right;
    // width: 36px;
    // height: 36px;
    z-index: 2;
    cursor: pointer;
    @include media-query($screen-md) {
      float: left;
    }
  }

  .open-menu {
    margin-top: 50px;
    margin-left: 25px;
    @include media-query($screen-md) {
      float: left;
    }
  }

  .open-menu-item {
    @include media-query($screen-md) {
      margin-top: 120px;
      .nav-menu-item {
        display: flex;
        flex-direction: column;
        hr {
          margin-top: 0px;
          margin-bottom: 23px;
          margin-left: 30px;
          margin-right: 37px;
          background-color: #C9D5C8;
        }
      }
      .menu {
        width: 250px;
        background-color: #34495e;
        padding: 20px;
        border-radius: 5px;
        font-family: Arial, sans-serif;
        color: #ffffff;
      }
      .menu-item {
        margin-bottom: 15px;
        @include media-query($screen-md) {
          hr {
            margin-top: 0px;
            margin-bottom: 23px;
            margin-left: 30px;
            margin-right: 37px;
            background-color: #C9D5C8;
          }
        }
      }
      .menu-item h2 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        cursor: pointer;
      }
      .menu-item a {
        color: #ffffff;
        text-decoration: none;
      }
      .toggle-input {
        display: none;
      }
      .toggle-label {
        cursor: pointer;
      }
      .submenu {
        display: none;
        margin-top: 10px;
        padding-left: 20px;
      }
      .toggle-input:checked ~ .submenu {
        display: block;
      }
      .submenu-item {
        margin-bottom: 8px;
        font-size: 14px;
      }
      .arrow {
        display: inline-block;
        margin-left: 5px;
        transition: transform 0.3s;
      }
      .toggle-input:checked ~ .toggle-label .arrow {
        transform: rotate(180deg);
      }
    }
  }


  input ~ .trigger {
    clear: both;
    display: none;
    z-index: 1;
    position: absolute;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
    height: calc(100vh);
    width: 100%;
    z-index: 4;
    top: -1px;
    left: 0px;
    background-color: #36443A;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
  }

  .page-link-open {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    // &:not(:last-child) {
    //   margin-right: 0;
    // }

    margin-left: 20px;
    @include media-query($screen-md) {
      text-align: start;
      color: #FFFBF5;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.96px;
      // border-bottom: 0.5px solid #C9D5C8;
      margin-right: 8%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include font-larken(400);
    }
  }

  .page-link-submenu {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
    @include media-query($screen-md) {
      text-align: start;
      color: #FFFBF5;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.8px;
      @include font-poppins(300);
    }
  }

  @media screen and (min-width: $screen-md) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
      margin-left: auto;
    }
  }
}

.site-nav-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  gap: 30px;

  @media screen and (min-width: $screen-md) {
    p {
      color: #36443A;
    }
  }

  @include media-query($screen-md) {
    display: none;
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $border-color-01;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit * .5;
}

.feed-subscribe .svg-icon {
  padding: 5px 5px 2px 0
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper,
.social-links {
  @include relative-font-size(0.9375);
  color: $brand-color;
}

.footer-col {
  margin-bottom: $spacing-unit * .5;
}

.footer-col-1,
.footer-col-2 {
  width: calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: calc(100% - (#{$spacing-unit} / 2));
}

@media screen and (min-width: $on-large) {
  .footer-col-1 {
    width: calc(35% - (#{$spacing-unit} / 2));
  }

  .footer-col-2 {
    width: calc(20% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: calc(45% - (#{$spacing-unit} / 2));
  }
}

@media screen and (min-width: $screen-md) {
  .footer-col-wrapper {
    display: flex
  }

  .footer-col {
    width: calc(100% - (#{$spacing-unit} / 2));
    padding: 0 ($spacing-unit * .5);

    &:first-child {
      padding-right: $spacing-unit * .5;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      padding-left: $spacing-unit * .5;
    }
  }
}



/**
 * Page content
 */
.page-content {
  padding-top: $header-height-desktop;
  flex: 1 0 auto;
  background-color: $creamColor;

  @media #{$mq-max-md} {
    padding-top: $header-height-mobile;
  }
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $brand-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1.15;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.625);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1, h2, h3, h4, h5, h6 { margin-top: $spacing-unit }

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h4 {
    @include relative-font-size(1.25);
  }

  h5 {
    @include relative-font-size(1.125);
  }
  h6 {
    @include relative-font-size(1.0625);
  }
}


.social-media-list {
  display: table;
  margin: 0 auto;
  li {
    float: left;
    margin: 5px 10px 5px 0;
    &:last-of-type { margin-right: 0 }
    a {
      display: block;
      padding: 10px 12px;
      border: 1px solid $border-color-01;
      &:hover { border-color: $border-color-02 }
    }
  }
}



/**
 * Pagination navbar
 */
.pagination {
  margin-bottom: $spacing-unit;
  @extend .social-media-list;
  li {
    a, div {
      min-width: 41px;
      text-align: center;
      box-sizing: border-box;
    }
    div {
      display: block;
      padding: $spacing-unit * .25;
      border: 1px solid transparent;

      &.pager-edge {
        color: $border-color-01;
        border: 1px dashed;
      }
    }
  }
}



/**
 * Grid helpers
 */
@media screen and (min-width: $on-large) {
  .one-half {
    width: calc(50% - (#{$spacing-unit} / 2));
  }
}
