.careers-page {
  .banner-section {
    display: flex;
    height: calc(100vh - $header-height-desktop);

    @media #{$mq-max-md} {
      display: none;
    }

    .image-container {
      width: 52%;

      @media #{$mq-max-lg} {
        width: 50%;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text-content-container {
      background-color: $darkColor;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48%;
      .text-content {
        text-align: center;
        .heading {
          font-size: $fs-xxl;
          @include font-larken(400);
          color: $creamColor;
          margin-bottom: 40px;
          
        }
      }
      .social-media-content-container {
        position: absolute;
        bottom: 2%;
        // margin: 120px 0 80px;
        .description {
          font-size: $fs-sm;
          color: $creamColor;
          @include font-poppins(400);
          text-align: center;
        }

        .sm-icons-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          column-gap: 20px;
        }
      }
    }
  }
  .hero {
    height: 500px;
    width: 100%;
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/james/james_with_tote_bag.avif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-x: 70%;
    @media screen and (min-width: $screen-md) {
      // height: 638px;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-position-x: initial;
      background-position-y: initial;
      // width: 60%;
    }
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    height: 638px;
  }

  .section-socialmedia {
    margin-top: 50px;

    .description {
      color: #FFF;
      text-align: center;
      font-size: 17px !important;
      line-height: 23px;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 10px;
      margin-top: 20px;
      @include font-poppins(400);
    }

    .sm-icon-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 42px;
      column-gap: 20px;
    }
  }

  .desktop {
    @media #{$mq-max-md} {
      display: none;
    }

    @media screen and (min-width: $screen-md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .mobile {
    display: none;
    @media #{$mq-max-md} {
      display: block;
    }
  }

  .hero-desktop {
    height: 419px;
    width: 100%;
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/careers/lead.jpeg");
    background-position-x: -125px;
    background-size: 183%;
    background-repeat: no-repeat;
    background-position-y: -103px;
  }

  .title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 17px;

    p {
      color: #36443A;
      text-align: center;
      font-size: 34px;
      @include font-larken(400);
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 18px;
    }
  }

  .faq-section {
    margin-top: 40px;
    padding-left: 40px;
    padding-right: 40px;

    hr {
      margin-top: 10px;
    }

    .accordion-item {
      .accordion-toggle {
        display: none;
      }

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .title {
          margin: 0px;
          color: #36443A;
          font-size: 20px;
          font-style: normal;
          line-height: normal;
          @include font-larken(400);
        }

        .icon-minus {
          display: none;
        }
      }

      .submenu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        margin-top: 10px;
        padding-left: 20px;

        .submenu-item {
          color: $darkColor;
          margin-bottom: 8px;
          font-size: 12px;
          @include font-poppins(400)
        }
      }

      .accordion-toggle:checked {
        &+.item {
          .icon-plus {
            display: none;
          }

          .icon-minus {
            display: block;
          }
        }

        &~.submenu {
          max-height: 1000px;
          /* Adjust this value based on your content */
          transition: max-height 0.5s ease-in;
        }
      }
    }
  }

  .journey-section {
    background: #C1D2C1;

    p {
      padding-top: 33px;
      text-align: center;
      color: #36443A;
      font-size: 32px;
      line-height: 45px;
      padding-left: 70px;
      padding-right: 70px;
      @include font-larken(400);
    }

    .product-image {
      height: 500px;
      width: 100%;
      background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/faq/product.png");
      background-position-y: 65px;
      background-position-x: -138px;
      background-repeat: no-repeat;
    }
  }

  .medium-text {
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;

    p {
      color: #36443A;
      font-size: 12px;
      line-height: 178%;
      @include font-poppins(400);
    }
  }

  .large-text {
    padding-left: 40px;
    padding-right: 40px;

    p {
      color: #36443A;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      @include font-larken(400);
    }
  }

  .image-1 {
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/careers/couple-exploring-products.jpeg");
    height: 274px;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .image-2 {
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/careers/couple-unboxing-products.jpeg");
    width: 100%;
    height: 418px;
    background-size: 100%;
    background-position-y: center;
    background-repeat: no-repeat;
    @media screen and (min-width: $screen-md) {
      background-size: cover;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .accordion-section {
    margin-top: 30px;
    @media screen and (min-width: $screen-md) {
      width: 95%;
      align-self: center;
    }

    .header-title {
      color: #36443A;
      font-family: Larken;
      font-size: $fs-lg;
      font-style: normal;
      font-weight: 400;
      line-height: 138%;
      padding-left: 40px;
      padding-right: 40px;
      @include font-larken(400);

      @media screen and (min-width: $screen-md) {
        font-size: $fs-xxl;
      }
    }
  }

  .text-container {
    padding: 20px 0;
  }

  .button-container {
    padding-bottom: 50px;
  }

  .opportunity-section {
    margin-top: 40px;
    padding-left: 40px;
    padding-right: 40px;

    hr {
      margin-top: 10px;
    }

    .accordion-item {
      padding: 0 10px;

      @media screen and (min-width: $screen-md) {
        padding: 0 20px;
      }
      .accordion-toggle {
        display: none;
      }

      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .title {
          margin: 0px;
          color: #36443A;
          font-size: 20px;
          font-style: normal;
          line-height: normal;
          @include font-larken(400);

          @media screen and (min-width: $screen-md) {
            font-size: $fs-2x;
          }
        }

        .icon-minus {
          display: none;
        }
      }

      .submenu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        margin-top: 10px;
        padding-left: 20px;

        .submenu-item {
          color: $darkColor;
          margin-bottom: 8px;
          font-size: 12px;
          @include font-poppins(200);
          @media screen and (min-width: $screen-md) {
            font-size: $fs-sm;
          }
        }
      }

      .accordion-toggle:checked {
        &+.item {
          .icon-plus {
            display: none;
          }

          .icon-minus {
            display: block;
          }
        }

        &~.submenu {
          max-height: fit-content;
          /* Adjust this value based on your content */
          transition: max-height 0.5s ease-in;
        }
      }
    }
  }

  .normal-text {
    color: #36443A;
    font-size: $fs-sm;
    line-height: 160%;
    @include font-poppins(200);
  }

  .bold-text {
    color: #36443A;
    font-size: 32px;
    line-height: 160%;
    @include font-larken-italic(400);
  }

  .first-section {
    display: flex;
    flex-direction: row;

    .text-container {
      width: 52%;
      padding: 50px;
      @media #{$mq-max-lg} {
        width: 50%;
      }
    }

    .image-section {
      width: 48%;

      @media #{$mq-max-lg} {
        width: 50%;
      }

      .image-1 {
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/careers/couple-exploring-products.jpeg");
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .second-section {
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    .image-section {
      width: 52%;
      padding-right: 50px;
      @media #{$mq-max-lg} {
        width: 50%;
      }
    }

    .text-container {
      width: 48%;
      padding-right: 40px;

      @media #{$mq-max-lg} {
        width: 50%;
      }
    }
  }
}
