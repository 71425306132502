.optimize-section {
  height: 732px;
  width: 100%;
  background:
    linear-gradient(0deg, rgba(54, 68, 58, 0.90) 0%, rgba(54, 68, 58, 0.90) 100%),
    url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/BentoColourShowcase_Green_130.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: $spacing-2x;

  p {
    margin: 0px;
  }

  div {
    width: 60%;
    text-align: center;
  }

  .title {
    color: $creamColor;
    text-align: center;
  }

  .large-text {
    color: $creamColor;
    text-align: center;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .medium-text {
    color: $creamColor;
    font-size: 24px;
    line-height: 27px;
    @include font-larken(400);
  }

  .footer-text {
    color: $creamColor;
    text-align: center;
    font-family: Larken;
    font-size: $fs-lg;
    line-height: 33px;
    @include font-larken(400);
  }
}

.optimize-section-desktop {
  padding: 50px;
  background:
    linear-gradient(0deg, rgba(54, 68, 58, 0.90) 0%, rgba(54, 68, 58, 0.90) 100%),
    url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/BentoColourShowcase_Green_130.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .title-container {
    .title {
      color: #FFFBF5;
      text-align: center;
    }

    svg {
      vertical-align: middle;
    }
  }

  .optimizations-container {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    align-items: center;

    .optimization {
      width: 20%;
      text-align: center;

      .large-text {
        color: $creamColor;
        text-align: center;
        line-height: 35px;
        margin-bottom: 10px;
      }

      .medium-text {
        color: $creamColor;
        font-size: 32px;
        line-height: 27px;
        @include font-larken(400);
      }
    }
  }
}
