.signature-page {
  @media screen and (min-width: $on-medium) {
    .hero {
      display: flex;
      height: calc(100vh - $header-height-desktop);

      .intro-video {
        width: 58%;
        object-fit: cover;
        height: 100%;
      }

      .text-content {
        width: 42%;
        background: #36443A;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .title {
        color: $creamColor;
        text-align: center;
        font-size: 42px;
        line-height: 47px;
        width: 64%;
        @include font-larken(400);
      }

      .description {
        color: $creamColor;
        text-align: center;
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 25px;
        padding-left: 45px;
        padding-right: 45px;
        width: 50%;
        @include font-poppins(400);
      }
    }

    .signature-features-slider-container {
      display: flex;

      .signature-features-slider {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        padding: 50px 0;
        row-gap: 30px;
        background-color: $creamColor;

        .feature-container {
          text-align: center;
          flex: 0 0 30%;

          p {
            width: 70%;
            font-size: $fs-sm;
            margin: 10px auto 0;
          }
        } 

        .btn-container {
          flex: 0 0 100%;
          text-align: center;
          margin-top: 20px;

          .green-button {
            width: 180px;
          }
        }
      }

      .features-image {
        width: 40%;
        height: 700px;
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/signature/man-putting-jar-in-tote.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: -380px;
      }

      // .intro-video {
      //   object-fit: cover;
      //   width: 40%;
      //   height: 675px;
      //   // height: 675px;
      // }
    }

    .signature-program {
      background: #C9D5C8;
      padding-bottom: 50px;

      .signature-title {
        padding-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;

        p {
          margin: 0;
          color: #36443A;
          text-align: center;
          font-size: 42px;
          line-height: 49px;
          @include font-larken(400);
        }
      }

      .signature-desc {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 5px;

        p {
          margin: 0;
          color: #36443A;
          text-align: center;
          font-size: 15px;
          line-height: 23px;
          @include font-poppins(400);
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
    }

    .results-section-container {
      display: flex;

      .results-image {
        width: 40%;
        height: 700px;
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/signature/results.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: center;
      }

      .results-section {
        background: #36443A;
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          color: #FFFBF5;
          text-align: center;
          font-size: 42px;
          line-height: 47px;
          width: 50%;
          @include font-larken(400);
        }
      }
    }
  }
  @include media-query($on-palm) {
    .hero {
      background-color: $creamColor;

      .text-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $creamColor;
        box-shadow: 0px -30px 60px 40px $creamColor;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -10px;
        z-index: 5;
        position: relative;
        padding-bottom: 20px;
      }

      .title {
        color: #354239;
        text-align: center;
        font-size: 28px;
        line-height: 36px;
        @include font-larken(400);
      }

      .description {
        color: #36443A;
        text-align: center;
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 17px;
        @include font-poppins(400);
      }

      .order-now-button {
        &:hover {
          background-color: $darkColor;
          color: $creamColor;
        }
      }
    }


    .signature-features-slider-container {
      .features-image {
        height: 546px;
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/signature/man-putting-jar-in-tote.jpeg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: -380px;
      }
    }

    .signature-features-slider {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding-bottom: 50px;
      padding-top: 25px;
      background-color: $creamColor;

      .feature-container {
        text-align: center;
      }

      p {
        margin: 10px auto 0;
        color: #36443A;
        line-height: 23px;
        width: 55%;
        @include font-poppins(400);
      }

      img {
        width: 60px;
        height: 60px;
      }

      .btn-container {
        margin-top: 20px;
      }
    }

    .intro-video {
      object-fit: cover;
    }

    .signature-program {
      background: #C9D5C8;
      padding-bottom: 50px;

      .signature-title {
        padding-top: 24px;

        p {
          margin: 0;
          color: #36443A;
          text-align: center;
          font-size: 34px;
          line-height: 41px;
          @include font-larken(400);
        }
      }

      .signature-desc {
        margin-top: 12px;

        p {
          margin: 0;
          color: #36443A;
          text-align: center;
          font-size: 15px;
          line-height: 23px;
          @include font-poppins(400);
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
    }

    .results-section-container {
      .results-image {
        height: 546px;
        width: 100%;
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/signature/results.jpeg");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
      }
    }

    .results-section {
      background: #36443A;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color: #FFFBF5;
        text-align: center;
        font-size: 34px;
        line-height: 41px;
        padding-top: 35px;
        @include font-larken(400);
      }
    }

    .slider-section {
      width: 100%;
    }
  }
}
