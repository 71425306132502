.blogs-page {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: $spacing-2x 0;
  .heading {
    text-align: center;
  }

  .w-container {
    width: 80%;
    margin: 0 auto;
    max-width: $screen-md;

    @media #{$mq-max-md} {
      width: 90%;
    }
  }

  .blog {
    border-bottom: 1px solid $darkColor;
    padding: $spacing-half 0;

    &__link {
      text-decoration: none;
      color: $darkColor;
    }


    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media #{$mq-max-md} {
        flex-direction: column;
        align-items: flex-start;
      }

      .author {
        display: flex;
        flex-direction: column;
        @media #{$mq-max-md} {
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
    &__title {
      margin-bottom: 0;
      &:hover {
        text-decoration: underline;
      }
    }

    &__description {
      padding-left: $spacing-base;
    }
  }
}

.blog-section {
  padding: $spacing-2x 0;
  .w-container {
    width: 80%;
    margin: 0 auto;
    max-width: $screen-md;

    @media #{$mq-max-md} {
      width: 90%;
    }
  }

  .blog {
    &__title {
      text-align: center;
    }
    &__author {
      text-align:  center;
    }

    &__content { 
      @include font-poppins(200);
      a {
       text-decoration: underline;
      }

      p, ul, li {
        @extend .md-para;
      }
    }
  }
}
