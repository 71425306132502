* {
  box-sizing: border-box;
}
.ourstory-page {
  background-color: $creamColor;
  .intro-section {
    position: relative;
    
    @media #{$mq-max-md} {
      display: block;
    }
    .image-container {
      width: 100%;

      @media #{$mq-max-md} {
        height: 400px;
        background: linear-gradient(180deg, rgba(255, 251, 245, 0.00) 70%, #FFFBF5 100%),
        url('https://d1cbtwb0qnacuk.cloudfront.net/images/ourstory/methodology-jars-bottle-products.jpeg');
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        
      }
  
      @media #{$mq-min-lg} {
        height: 600px;
      }
  
      @media #{$mq-min-xl} {
        height: 700px;
      }
  
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media #{$mq-max-md} {
          display: none;
        }
      }
    }

    .text-container {
      position: absolute;
      top: 15%;
      right: 10%;
      color: $creamColor;
      text-align: center;
      @media #{$mq-max-md} {
        position: relative;
        top: -20px;
        right: unset;
        color: $darkColor;
        box-shadow: 0px -90px 99px 20px $creamColor;
      }

      .heading {
        font-size: $fs-5x;
        margin-bottom: 0;
        @include font-larken(400);

        @media #{$mq-max-md} {
          font-size: $fs-xl;
        }
      }

      .description {
        font-size: $fs-sm;
        margin-top: -10px;
        @include font-poppins(400);
        @media #{$mq-max-md} {
          margin-top: 5px;
        }
      }
    }
  }

  .julie-video-section {
    display: flex;
    align-items: flex-start;
    height: auto;

    @media #{$mq-max-md} {
      display: block;
    }

    .about-julie-container {
      width: 50%;
      padding: 20px 50px;
      padding-right: 0px;
      flex-grow: 0;
      text-align: center;

      @media #{$mq-max-md} {
        width: 100%;
        padding: 0;
      }

      .heading {
        margin-top: 40px;
        @include font-larken(400);
        font-size: $fs-3x;
        color: $darkColor;

        @media #{$mq-max-md} {
          font-size: $fs-md;
          margin-top: 20px;
        }
      }

      .designation {
        color: $darkColor;
        @include font-poppins(400);
        font-size: $fs-sm;
        margin-bottom: 40px;

        @media #{$mq-max-md} {
          font-size: $fs-xs;
          margin-bottom: 20px;
        }
      }

      .description-container {
        line-height: 1.5;
        color: $darkColor;
        font-size: $fs-sm;
        text-align: start;
        @include font-poppins(200);

        @media #{$mq-max-md} {
          padding: 25px 50px;
          font-size: $fs-xs;
        }
      }

      .founder-image-container {
        .image {
          border-radius: 50%;
          width: 160px;
          height: 160px;
          @media #{$mq-min-md} {
            width: 300px;
            height: 300px;
          }
        }
      }
    }

    .video-container {
      width: 50%;
      height: 100%;
      padding-left: 80px;

      @media #{$mq-max-md} {
        width: 100%;
        height: 525px;
        padding-left: 0px;
      }

      .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .explanation-container {
    color: $darkColor;
    display: flex;
    margin-top: 80px;
    align-items: flex-start;

    @media #{$mq-max-md} {
      display: block;
      margin-top: 0;
    }

    .left-section {
      width: 50%;
      @media #{$mq-max-md} {
        width: 100%;
      }

      .large-text {
        font-size: $fs-xl;
        line-height: 1.5;
        @include font-larken-italic(400);
        font-style: italic;
        // padding-left: 100px;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 40px;
        @media #{$mq-max-md} {
          font-size: $fs-md;
          max-width: 100%;
          margin: 0;
          padding: 0 50px 40px;
        }
      }

      .image-container {
        height: 100%;
        max-height: 746px;
        width: 100%;
        .image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .right-section {
      width: 50%;
      padding: 0 80px;
      @include font-poppins(200);

      @media #{$mq-max-md} {
        width: 100%;
        padding: 0 50px;
      }

      .description-text {
        line-height: 2;
        font-size: $fs-sm;
        text-align: start;
        margin-bottom: 30px;

        @media #{$mq-max-md} {
          padding: 0;
          font-size: $fs-xs;
          margin-bottom: 20px;
        }
      }

      .large-text {
        line-height: 1.5;
        font-size: $fs-xl;
        @include font-larken-italic(400);
        font-style: italic;
        @media #{$mq-max-md} {
          font-size: $fs-md;
          max-width: 100%;
          margin: 0;
          padding: 20px 0 40px;
        }
      }
    }
  }

  .second-explanation-container {
    display: flex;
    margin-top: 80px;

    @media #{$mq-max-md} {
      flex-direction: column-reverse;
      margin-top: 0px;
    }

    .left-section {
      color: $darkColor;
      width: 50%;
      padding-left: 80px;
      @media #{$mq-max-md} {
        width: 100%;
        padding: 25px 50px 0;
      }
      .description {
        font-size: $fs-sm;
        text-align: start;
        line-height: 2;
        margin-bottom: 30px;
        @include font-poppins(200);

        @media #{$mq-max-md} {
          padding: 0;
          font-size: $fs-xs;
          margin-bottom: 20px;
        }
      }

      .large-text {
        line-height: 1.5;
        font-size: $fs-xl;
        @include font-larken-italic(400);
        font-style: italic;
        margin: 0 auto;
        @media #{$mq-max-md} {
          font-size: $fs-md;
          max-width: 100%;
          margin: 0;
          padding: 20px 0 40px;
        }
      }
    }

    .right-section {
      width: 50%;
      flex-grow: 1;
      padding-left: 80px;
      @media #{$mq-max-md} {
        width: 100%;
        height: 400px;
        padding: 0;
      }

      .image-container {
        height: 100%;
        background: url('https://d1cbtwb0qnacuk.cloudfront.net/images/ourstory/couple-having-dinner.jpeg');
        // background-color: orange;
        background-size: cover;
        background-position: left;
        @media #{$mq-max-md} {
          background-position: left;
        }
      }
    }
  }

  .third-explanation-container {
    color: $darkColor;
    display: flex;
    margin-top: 40px;

    @media #{$mq-max-md} {
      margin-top: 0;
      display: block;
    }

    .left-section {
      width: 50%;
      padding-left: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include font-poppins(200);

      @media #{$mq-max-md} {
        width: 100%;
        padding: 0 50px;
      }
      .description-text {
        font-size: $fs-sm;
        text-align: start;
        line-height: 2;
        // margin-bottom: 30px;

        @media #{$mq-max-md} {
          padding: 0;
          font-size: $fs-xs;
          // margin-bottom: 20px;
        }
      }
    }
    
    .right-section {
      width: 50%;
      padding: 0 80px;

      @media #{$mq-max-md} {
        width: 100%;
        padding: 0 50px;
      }

      .large-text {
        line-height: 1.5;
        font-size: $fs-xl;
        @include font-larken-italic(400);
        font-style: italic;
        margin: 0 auto;
        margin-bottom: 40px;
        @media #{$mq-max-md} {
          font-size: $fs-md;
          max-width: 100%;
          margin: 0;
          padding: 20px 0 40px;
        }
      }
    }
  }

  .fourth-container {
    // width: 80%;
    margin: 40px auto;
    color: $darkColor;

    @media #{$mq-max-md} {
      margin: 0px auto;
    }

    .large-text {
      line-height: 1.5;
      font-size: $fs-lg;
      @include font-larken(400);
      margin: 0 auto;
      text-align: center;
      padding: 0 50px;
      @media #{$mq-max-md} {
        @include font-poppins(400);
        font-size: $fs-xs;
        max-width: 100%;
        margin: 0;
        line-height: 2;
        margin-bottom: 20px;
        text-align: left;
      }
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }

  /* Hide all controls except play/pause */
  .julie-video-section {
    .video::-webkit-media-controls-timeline,
    .video::-webkit-media-controls-current-time-display,
    .video::-webkit-media-controls-time-remaining-display,
    .video::-webkit-media-controls-volume-slider,
    .video::-webkit-media-controls-fullscreen-button,
    .video::-webkit-media-controls-rewind-button,
    .video::-webkit-media-controls-return-to-realtime-button,
    .video::-webkit-media-controls-playback-rate-menu-button,
    .video::-webkit-media-controls-overflow-button,
    .video::-webkit-media-controls-toggle-closed-captions-button {
      display: none;
    }

    .video::-webkit-media-controls-mute-button,
    .video::-webkit-media-controls-play-button {
      background-size: 35px;
    }

    .video::-webkit-media-controls-panel {
      background: none !important;
      background-image: none !important;
    }

    /* For Firefox */
    .video::-moz-range-track {
      display: none;
    }

    /* For IE/Edge */
    .video::-ms-track {
      display: none;
    }
  }
}
