.new-our-programs {
  // TODO: Remove this and move to reset, after removing the minima theme.
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  background-color: $creamColor;
  padding: $spacing-3x 0;
  @media #{$mq-max-md} {
    background-color: $lightGreenColor;
    padding: $spacing-base 0 $spacing-2x;
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .w-container {
    width: fit-content;
    margin: 0 auto;

    @media #{$mq-max-md} {
      width: 90%;
    }
  }

  .title {
    text-align: center;
  }

  .programs-container {
    margin-top: $spacing-base;
    display: flex;
    gap: $spacing-3x;

    @media #{$mq-max-md} {
      flex-direction: column;
      gap: $spacing-base;
    }
  }

  .program {
    width: 213px;
    display: flex;
    flex-direction: column;

    @media #{$mq-max-md} {
      flex-direction: row;
      width: 100%;
      gap: $spacing-base;
    }

    &:nth-child(2) {
      @media #{$mq-max-md} {
        border-top: 1px solid $darkColor;
        border-bottom: 1px solid $darkColor;
        padding: $spacing-base 0;
      }
    }
    
    &__image {
      border-radius: 10px;
      width: 100%;
      height: 213px;
      @media #{$mq-max-md} {
        width: 140px;
        height: 140px;
        align-self: center;
      }
    }

    &__name {
      margin-top: $spacing-half;
      line-height: 1.2;
      @media #{$mq-max-md} {
        margin-top: 0;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      @media #{$mq-max-md} {
        justify-content: start;
      }
    }

    &__description {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $spacing-half;
      @media #{$mq-max-md} {
        justify-content: space-around;
      }
    }

    &__link {
      color: $darkColor;
      font-size: 14px;
      @include font-poppins(300);
      @media #{$mq-max-md} {
        font-size: $fs-xs;
        @include font-poppins(400);
      }
    }
  }
}
