.section-good-planet {
  background-color: $creamColor;
  padding-bottom: 30px;
  padding-top: 10px;

  @media screen and (min-width: $on-medium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }

  .title {
    margin-top: 29px;
    color: #36443A;
    text-align: center;
    font-size: $fs-xl;
    font-style: normal;
    line-height: 41px;
    margin-bottom: 20px;
    @include font-larken(400);
    div {
      color: #36443A;
    }

    @media screen and (min-width: $on-medium) {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      gap: 5px;
      font-size: $fs-xxl;
    }
  }

  .planet-details {
    @media screen and (min-width: $on-medium) {
      display: flex;
      flex-direction: row;
      width: 80%;
      margin-top: 30px;
    }

    @media screen and (min-width: $on-large) {
      width: 75%;
      display: flex;
      flex-direction: row;
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 13px;

      @media screen and (min-width: $on-medium) {
        justify-content: flex-start;
        flex-direction: column;
        width: 30%;
      }
      @media screen and (min-width: $on-large) {
        flex-direction: column;
        width: 30%;
      }
    }

    img {
      border-radius: 100px;
      height: 100px;
      width: 100px;

      @media screen and (min-width: $on-medium) {
        width: 166px;
        height: 166px;
        margin-bottom: 32px;
      }
    }

    p {
      margin: 0px;
      width: 60%;
      font-size: 13px;
      margin-left: 17px;
      @include font-poppins(400);
      @media screen and (min-width: $on-medium) {
        font-size: $fs-sm;
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
