.transformation-page {

  // @include media-query($on-palm) {
  .intro-video {
    object-fit: cover;
    position: relative;
  }

  .hero {
    top: 0px !important;
    position: relative;

    @media screen and (min-width: $on-medium) {
      display: none;
    }

    .text-content {
      top: -32px;
      position: relative;
      background: white;
      box-shadow: 0px -1px 81px 70px rgb(255, 255, 255);
    }

    .title {
      color: #354239;
      text-align: center;
      font-size: 28px;
      line-height: 36px;
      padding-left: 30px;
      padding-right: 30px;
      position: relative;
      top: -10px;
      @include font-larken(400);
    }

    .description {
      color: #36443A;
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 17px;
      padding-left: 45px;
      padding-right: 45px;
      @include font-poppins(400);
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 25px;
    }
  }

  .hero-desktop {
    display: flex;
    flex-direction: row;
    height: calc(100vh - $header-height-desktop);

    @include media-query($on-palm) {
      display: none;
    }

    .video-section {
      width: 58%;
      height: 100%;

      .intro-video {
        height: 100%;
      }
    }

    .text-content {
      height: 100%;
      width: 42%;
      background: #36443A;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .title {
      color: #FFFBF5;
      text-align: center;
      font-size: 42px;
      line-height: 47px;
      width: 90%;
      @include font-larken(400);
    }

    .description {
      color: #FFFBF5;
      text-align: center;
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 17px;
      padding-left: 45px;
      padding-right: 45px;
      width: 90%;
      @include font-poppins(400);
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 25px;
    }
  }

  .battle-section {
    background-color: $midLightGreenColor;
    position: relative;

    @media #{$mq-max-md} {
      display: flex;
      flex-direction: column-reverse;
    }

    .text-container {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 40px;

      @media #{$mq-max-md} {
        position: static;
        width: 80%;
        margin: 0 auto 30px;
      }

      .heading-text {
        max-width: 700px;
        margin: 0 auto;
        color: $darkColor;
        font-size: $fs-xxl;
        @include font-larken(400);

        @media #{$mq-max-md} {
          font-size: $fs-xl;
        }
      }
    }
    .image-container {
      background-color: $midLightGreenColor;

      @media #{$mq-max-md} {
        overflow: hidden;
      }

      .image {
        max-height: 700px;
        width: 100%;
        object-fit: contain;

        @media #{$mq-max-md} {
          scale: 1.4;
          object-fit: cover;
          object-position: 0 -20px;
        }
      }
    }
  }

  .kitchen-image {
    width: 100%;
    height: 457px;
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/transformation/kitchen.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -70px;
    position: relative;

    @media screen and (min-width: $on-medium) {
      display: none;
    }
  }

  .variation-section {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: $creamColor;

    @media screen and (min-width: $on-medium) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .title {
      color: #36443A;
      text-align: center;
      font-size: 34px;
      line-height: 41px;
      padding-left: 50px;
      padding-right: 50px;
      margin: 0px;
      @include font-larken(400);
      @media screen and (min-width: $on-medium) {
        font-size: $fs-xxl;
      }
    }

    .description {
      color: #36443A;
      text-align: center;
      font-size: 15px;
      line-height: 23px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 10px;
      margin-top: 10px;
      @include font-poppins(400);
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      @media screen and (min-width: $on-medium) {
        margin-top: 50px;
      }
    }
  }

  .variation-scroll {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    column-gap: 25px;
    // padding-bottom: 35px;
    scrollbar-width: none;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory; 

    @media screen and (min-width: $on-medium) {
      justify-content: center;
    }

    .variation-item {
      border-radius: 20px;
      border: 1px solid #000;
      background-color: #C9D5C8;
      scroll-snap-align: center;

      p {
        margin: 0px;
      }

      .item-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 268px;
        height: 385px;
      }

      .small-text {
        color: #36443A;
        text-align: center;
        font-size: 18px;
        line-height: 38px;
        text-transform: capitalize;
        @include font-poppins(500);
      }

      .large-text {
        color: #36443A;
        font-size: 32px;
        line-height: 38px;
        text-transform: capitalize;
        @include font-larken(400);
      }

      .option {
        background: #36443A;
        border-radius: 5px;
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 10px;

        .right-col {
          width: 40%;
        }

        .left-col {
          width: 60%;
        }

        .col-div {
          display: flex;
          flex-direction: column;
          align-items: center;

          .days {
            color: #C9D5C8;
            font-size: 20px;
            line-height: 23px;
            @include font-poppins(600);
          }

          .occurrence {
            color: #C9D5C8;
            text-align: center;
            font-size: 15px;
            line-height: 23px;
            @include font-poppins(400);
          }

          .food-detail {
            color: #C9D5C8;
            text-align: center;
            font-size: 12px;
            line-height: 19px;
            text-transform: capitalize;
            @include font-poppins(400);
          }

          .food-detail-bold {
            color: #C9D5C8;
            font-size: 12px;
            line-height: 19px;
            text-transform: capitalize;
          }
        }
      }
    }

    .variation-item:first-child {
      margin-left: 20%;
    }

    .variation-item:last-child {
      margin-right: 20%;
    }
  }

  .makeover-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #9CC196;
    padding-bottom: 36px;

    @media screen and (min-width: $on-medium) {
      display: none;
    }

    p {
      margin: 0px;
    }

    .image-container {
      width: 100%;
  
      .image {
        width: 100%;
      }
    }

    .title {
      margin-top: 20px;
      color: #36443A;
      text-align: center;
      font-size: 28px;
      line-height: 41px;
      width: 80%;
      margin-bottom: 10px;
      @include font-larken(400);
    }

    .description {
      color: #36443A;
      text-align: center;
      font-size: 15px;
      line-height: 23px;
      margin: 0px;
      width: 76%;
      margin-top: 10px;
      margin-bottom: 30px;
      @include font-poppins(400);
    }

    .bubble-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 19px;

      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border-radius: 100px;
        background-color: #C9D5C8;
        width: 40px;
        height: 40px;
        color: #36443A;
        text-align: center;
        font-size: 25px;
        line-height: 135%;
        left: 25px;
        top: -13px;
        border: 1px solid #36443A;
        @include font-larken(400);
      }

      .bubble-container {
        border-radius: 17px;
        border: 1px solid #000;
        background: #C9D5C8;
        width: 76%;
        height: fit-content;
        align-self: center;

        .text-content {
          color: #354439;
          font-size: 15px;
          line-height: 22px;
          padding-left: 24px;
          padding-right: 16px;
          padding-top: 10px;
          padding-bottom: 11px;
          @include font-poppins(500);
        }
      }
    }
  }

  .mindset-makeover-desktop {
    display: flex;

    @media screen and (max-width: $on-medium) {
      display: none;
    }

    @media #{$mq-min-lg} {
      max-height: 700px;
    }

    .image-container {
      width: 40%;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
    .content-container {
      background-color: $midGreenColor;
      width: 60%;
      padding: 50px;
      color: $darkColor;
      display: flex;
      align-items: center;

      .grid-and-heading-container {
        height: fit-content;
      }

      .title {
        font-size: $fs-xxl;
        max-width: 600px;
        @include font-larken(400);
      }

      .description {
        font-size: $fs-sm;
        margin-bottom: 40px;
        @include font-poppins(400);
      }

      .bubbles-grid {
        // background-color: orange;
        max-width: 700px;

        .bubbles-row {
          // background-color: green;
          display: flex;
          margin-left: 20px;

          &:first-child {
            gap: 60px;
            align-items: center;
          }
          &:last-child {
            gap: 40px;
            margin-top: 30px;
          }
          .bubble-wrapper {
            // background-color: red;
            max-width: 310px;

            .bubble-container {
              height: fit-content;
              // background-color: aqua;
              border-radius: 17px;
              border: 1px solid #000;
              padding: 10px 25px;
              position: relative;
              background-color: $lightGreenColor;

              .badge {
                position: absolute;
                top: -10px;
                left: -25px;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #36443A;
                background-color: $lightGreenColor;
                font-size: $fs-md;
                @include font-larken(400);
              }

              .text-content {
                margin-bottom: 0;
                font-size: $fs-sm;
                @include font-poppins(500);
              }
            }
          }
        }
      }
    }
  }

  .makeover-section .bubble-wrapper:nth-of-type(3) .bubble-container {
    align-self: flex-end;
    margin-right: 32px;
    width: 70%;
  }

  .makeover-section .bubble-wrapper:nth-of-type(3) .badge {
    left: 17%;
  }

  .makeover-section .bubble-wrapper:nth-of-type(4) .bubble-container {
    align-self: flex-start;
    margin-right: 32px;
  }

  .makeover-section .bubble-wrapper:nth-of-type(4) {
    width: 80%;
  }

  .makeover-section .bubble-wrapper:nth-of-type(4) .badge {
    left: -6%;
  }

  .signature-program {
    background: #C9D5C8;
    padding-bottom: 50px;

    .signature-title {
      padding-top: 24px;

      p {
        margin: 0;
        color: #36443A;
        text-align: center;
        font-size: 34px;
        line-height: 41px;
        @include font-larken(400);

        @media screen and (min-width: $on-medium) {
          font-size: $fs-xxl;
        }
      }
    }

    .signature-desc {
      margin-top: 12px;

      p {
        margin: 0;
        color: #36443A;
        text-align: center;
        font-size: 15px;
        line-height: 23px;
        @include font-poppins(400);
      }
    }
  }

  .results-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/transformation/kitchen-2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: -189px;
    @media screen and (min-width: $on-medium) {
      width: 50%;
      height: 576px;
      background-position-y: center;
    }
  }

  .results-section {
    background: #36443A;
    padding-bottom: 30px;
    position: relative;
    top: -10px;
    @media screen and (min-width: $on-medium) {
      top: 0px;
      width: 50%;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 576px;
    }

    p {
      margin: 0px;
    }

    .title {
      color: #FFFBF5;
      text-align: center;
      font-size: $fs-xl;
      line-height: 41px;
      padding-top: 35px;
      padding-left: 45px;
      padding-right: 45px;
      @include font-larken(400);

      @media screen and (min-width: $on-medium) {
        font-size: $fs-xxl;
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      @media screen and (min-width: $on-medium) {
        margin-top: 0px;
      }
    }
  }
  .footer-section {
    @media screen and (min-width: $on-medium) {
      display: flex;
      flex-direction: row;
    }
    @include media-query($on-palm) {
      display: flex;
      flex-direction: column;
    }
  }
}
