.letter-container {
  width: 80%;
  max-width: 1200px;
  margin: 50px auto 0;

  .letter-heading-container {
    @include font-larken(400);
    font-size: $fs-xxl;
    text-align: center;
    color: $darkColor;
    @media #{$mq-max-md} {
      font-size: $fs-xl;
    }
  }

  .letter {
    color: $darkColor;
      @include font-poppins(400);
      font-size: $fs-sm;
      width: 60%;
      margin: 0 auto;

      @media #{$mq-max-md} {
        width: 90%;
        font-size: $fs-xs;
      }
    .to-part {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .content-part {
      margin-top: 50px;
      .underline {
        text-decoration: underline;
      }
    }
  }
}
