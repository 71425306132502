@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$on-medium:        820px !default;
$on-large:         1600px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: #{$ratio}rem;
}

@mixin font-larken($font-weight) {
  font-family: 'Larken', 'Albra', sans-serif !important;
  font-weight: $font-weight !important;
  font-style: normal;
}

@mixin font-larken-italic($font-weight) {
  font-family: 'Larken Italic', 'Albra', sans-serif !important;
  font-weight: $font-weight !important;
  font-style: normal;
}

@mixin font-poppins($font-weight) {
  font-family: 'Poppins', 'Helvetica Neue', sans-serif !important;
  font-weight: $font-weight !important;
  font-style: normal;
}

// Import pre-styling-overrides hook and style-partials.
@import
  "minima/custom-variables", // Hook to override predefined variables.
  "minima/base",             // Defines element resets.
  "minima/layout",           // Defines structure and style based on CSS selectors.
  "minima/custom-styles",     // Hook to override existing styles.
  "footer",
  "home",
  'home/our_programs',
  "good-planet",
  "signature",
  "transformation",
  "ourstory",
  "ultimate",
  "postpartum",
  'optimize_gut_health',
  "family",
  "contact",
  "faq",
  "careers",
  "privacy",
  'accessibility',
  'blog'
;
