.home-page {
  background-color: $creamColor;
  .hero-desktop {
    display: none;

    @media screen and (min-width: $on-medium) {
      display: flex;
      flex-direction: row;
      height: calc(100vh - $header-height-desktop);

      .lead-image {
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/home/homepage-banner.jpg");
        background-repeat: no-repeat;
        width: 58%;
        height: 100%;
        background-size: cover;
        background-position-y: center;
      }

      .lead-text-content {
        width: 42%;
        background: #36443A;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          color: #FFFBF5;
          text-align: center;
          @include font-larken(400);
          font-size: $fs-xxl;
          font-style: normal;
          line-height: 45px;
          margin: 0px;
        }
      }

      .hero-text-footer {
        width: 80%;

        p {
          color: #FFF;
          text-align: center;
          font-size: 18px;
          line-height: 27px;
          @include font-poppins(500);
        }
      }
    }

    @media screen and (min-width: $on-large) {
      display: flex;
      flex-direction: row;
      height: calc(100vh - $header-height-desktop);

      .lead-image {
        background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/home/homepage-banner.jpg");
        background-repeat: no-repeat;
        width: 55%;
        height: 100%;
        background-size: 100%;
        background-position-y: -290px;
      }

      .lead-text-content {
        width: 45%;
        background: #36443A;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          color: #FFFBF5;
          text-align: center;
          @include font-larken(400);
          font-size: $fs-xxl;
          line-height: 45px;
          margin: 0px;
        }
      }

      .hero-text-footer {
        width: 80%;

        p {
          color: #FFF;
          text-align: center;
          font-size: 18px;
          line-height: 27px;
          @include font-poppins(500);
        }
      }
    }
  }

  .hero {
    min-height: 621px;
    padding: 28px 28px;
    position: relative;
    top: 0 !important;
    left: 0;
    background: url("https://d1cbtwb0qnacuk.cloudfront.net/images/home/homepage-banner.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 116%;
    box-shadow: inset 0px -160px 43px 20px rgba(0, 0, 0, .3), inset 1px 151px 138px 20px rgba(0, 0, 0, .3);

    .content {
      margin-top: 0;
      position: relative;
      width: 100%;
      left: 0;

      .hero-content-inner {
        width: 100%;

        .hero-text {
          margin: 0 0 16px 0;
          width: 100%;

          p {
            text-align: center;
            font-size: 28px;
            color: #FFFBF5;
            line-height: 32px;
            margin: 0;
            padding: 0;
            @include font-larken(400);
          }
        }

        .hero-text-footer {
          margin: 0 0 16px 0;
          width: 100%;

          p {
            color: #fff;
            font-size: 15px;
            line-height: 23px;
            text-align: center;
            margin: 0;
            @include font-poppins(400);
          }
        }
      }

      .hero-content-footer {
        display: flex;
        flex-direction: column;
        min-height: 509px;
        justify-content: flex-end;
        align-items: center;
      }

      h1 {
        margin-top: 0
      }

      h3 {
        font-size: 18px
      }
    }

    @media screen and (min-width: $on-medium) {
      display: none;
    }
  }

  .intro-video {
    object-fit: cover;
  }

  .checkboxes-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    @media screen and (min-width: $on-medium) {
      padding-left: 50px;
      align-items: center;
      width: 50%;
      margin-top: 0px;
    }

    @media screen and (min-width: $on-large) {
      padding-left: 50px;
      align-items: center;
      width: 50%;
      margin-top: 0px;
    }

    .title {
      margin: 1px;
      font-size: 34px;
      text-align: center;
      line-height: 38px;
      color: $darkColor;
      @include font-larken(400);

      @media screen and (min-width: $on-medium) {
        color: #36443A;
        text-align: center;
        font-size: 42px;
        line-height: 55px;
        @include font-larken(400);
      }
    }

    .benefit-list-wrapper {
      @include media-query($on-palm) {
        padding-left: 38px;
        margin-bottom: 40px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      @media screen and (min-width: $on-medium) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
        gap: 30px;
        margin: 0 auto;
        width: 100%;
        padding-bottom: 30px;
      }

      @media screen and (min-width: $on-large) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
        gap: 20px;
        margin: 0 auto;
        width: 80%;
        padding-bottom: 30px;
      }

      .benefit {
        gap: 10px;
        display: flex;
        align-items: center;

        p {
          margin: 0;
          font-size: 15px;
          @include font-poppins(500);
          line-height: 25px;
          color: $darkColor;
        }
        .cost-per-meal {
          border-bottom: 3px double $darkColor;
          font-size: 18px;
        }
      }
    }

    .btn-wrapper {
      width: 50%;
    }
  }

  .video-checkbox {
    .intro-video {
      object-fit: cover;
      width: 100%;
      height: 474px;

      @media screen and (min-width: $on-medium) {
        width: 50%;
        height: 100%;
      }
    }

    @media screen and (min-width: $on-medium) {
      display: flex;
      flex-direction: row;
      height: 720px;
    }

    @media screen and (min-width: 820px) and (max-width: 923px) {
      height: 800px;
    }

    @media screen and (min-width: $on-large) {
      display: flex;
      flex-direction: row;
      height: 578px;
    }

    @media screen and (min-width: $on-palm) {}
  }

  .our-programs {
    margin-top: 32px;
    background-color: #C9D5C8;
    padding-bottom: 35px;

    @media screen and (min-width: $on-medium) {
      margin-top: 0px;
      padding-bottom: 0px;
    }

    .container {
      padding-bottom: 0px;

      @media screen and (min-width: $on-medium) {
        padding-bottom: 30px;
      }
    }

    .signature-title {
      padding-top: 24px;

      @media screen and (min-width: $on-medium) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
      }

      p {
        margin: 0;
        color: #36443A;
        text-align: center;
        font-size: 34px;
        line-height: 41px;
        @include font-larken(400);

        @media screen and (min-width: $on-medium) {
          color: #36443A;
          text-align: center;
          font-size: 42px;
          line-height: 49px;
          @include font-larken(400);
        }
      }
    }

    .signature-desc {
      margin-top: 12px;

      @media screen and (min-width: $on-medium) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 5px;
      }

      p {
        margin: 0;
        color: #36443A;
        text-align: center;
        font-size: 15px;
        line-height: 23px;
        @include font-poppins(400);
      }
    }

    .title {
      padding-top: 30px;
      color: $darkColor;
      text-align: center;
      font-size: 34px;
      @media screen and (min-width: $on-medium) {
        font-size: $fs-xxl;
      }
      line-height: 41px;
      @include font-larken(400);
    }
  }

  .transformation-section-desktop {
    display: none;

    @media screen and (min-width: $on-medium) {
      display: flex;

      .transform-img {
        background: url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/start-transformation.jpeg);
        width: 50%;
        background-size: cover;
        background-position-x: center;
        background-repeat: no-repeat;
        max-width: 1200px;
      }

      .transform-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 579px;
        width: 50%;
        background-color: #36443A;
        gap: 10px;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        color: #FFFBF5;
        text-align: center;
        font-size: $fs-xxl;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1875rem;
        text-transform: capitalize;
        padding-bottom: 10px;
        @include font-larken(400);
      }

      .zip-code-desktop {
        color: #FFF;
        font-size: $fs-sm;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        padding-top: 2px;
        align-self: center;
        @include font-poppins(400);
      }

      .input-button-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      .zip-code-input-desktop {
        padding: 14px 16px;
        align-self: center;
        display: inline-flex;
        width: 365px;
        margin-top: 11px;
        font-size: 16px;
        @include font-poppins(400);
      }

      .zipcode-button {
        margin-top: 20px;
        font-size: $fs-sm;
      }

      .btn-wrapper {
        width: 365px;
        display: inline-flex;
        align-self: center;
        justify-content: center;
        border-radius: 5px;
        background: #C1D3C1;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.10);
        height: 50px;
        align-items: center;
        margin-top: 16px;

        .check-avail-btn-desktop {
          width: 100%;
        }
      }
    }
  }

  .transformation-section {
    box-shadow: inset 0px -280px 47px 0px #36443A;
    background: url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/start-transformation.jpeg);
    height: 661px;
    padding: 36px 37px;
    position: relative;
    top: 0;
    left: 0;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-bottom: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    background-position-y: 0px;
    background-size: 180%;
    background-position-x: -139px;

    @media screen and (min-width: $on-medium) {
      display: none;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 100%;
      color: #FFFBF5;
      text-align: center;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1875rem;
      text-transform: capitalize;
      padding-bottom: 10px;
      @include font-larken(400);
    }

    .zip-code {
      color: #FFF;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      padding-top: 2px;
      align-self: center;
      @include font-poppins(400);
    }

    .zip-code-input {
      padding: 14px 16px;
      align-self: center;
      display: inline-flex;
      width: 100%;
      margin-top: 11px;
      font-size: 16px;
      @include font-poppins(400);
    }

    .btn-wrapper {
      width: 100%;
      display: inline-flex;
      align-self: center;
      justify-content: center;
      border-radius: 5px;
      background: #C1D3C1;
      box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.10);
      height: 50px;
      align-items: center;
      margin-top: 16px;

      .green-button:hover {
        background-color: $creamColor;
        cursor: pointer;

        span {
          color: $darkColor;
        }
      }
      .check-avail-btn {
        font-size: $fs-sm;
        width: 100%;
      }
    }
  }

  .intro-video-section {
    @media screen and (min-width: $on-medium) {
      display: none;
    }
  }

  .ingredient-intro-video {
    display: flex;

    @media screen and (min-width: $on-medium) {
      flex-direction: row;
      justify-content: space-between;

      video {
        width: 50%;
      }
    }

    .ingredient-intro-video {
      display: none;
    }

    .ingredient-wrapper {
      display: flex;
      flex-direction: column;
      background-color: $creamColor;
      width: 100%;

      @media screen and (min-width: $on-medium) {
        width: 50% !important;
        justify-content: center;
      }

      @media screen and (min-width: $on-palm) {
        width: 100%;
      }
    }

    .intro-video-desktop {
      display: none;

      @media screen and (min-width: $on-medium) {
        display: block;
      }
    }
  }

  .next-level-meals {
    color: #36443A;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    line-height: 41px;
    margin-top: 5px;
    padding-top: 25px;
    background-color: $creamColor;
    @include font-larken(400);

    @media screen and (min-width: $on-medium) {
      display: none;
    }
  }

  .next-level-meals-desktop {
    color: #36443A;
    text-align: center;
    font-size: $fs-xxl;
    font-style: normal;
    line-height: 41px;
    margin-top: 5px;
    @include font-larken(400);
    display: none;

    @media screen and (min-width: $on-medium) {
      display: block;
    }
  }

  .images-ingredients {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 30px auto;
    gap: 20px;
    width: 90%;

    @media screen and (min-width: $on-medium) {
      width: 84%;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .images-ingredients div {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $on-palm) {
      &:last-child {
        grid-column: 2;
      }
    }
  }

  .order-now-btn {
    justify-content: center;
    display: flex;
    margin-top: 26px;

    @media screen and (max-width: $on-palm) {
      margin-bottom: 26px;
    }
  }

  .lose-weight-wrapper {
    background-color: #36443a;
    padding-bottom: 36px;

    @media screen and (min-width: $on-medium) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0px;
    }

    .lose-weight-section {
      background:
        linear-gradient(180deg, rgba(54, 68, 58, 0.00) 70%, #36443A 100%),
        url('https://d1cbtwb0qnacuk.cloudfront.net/images/james/james_with_tote_bag.avif');
      height: 541px;
      margin-bottom: 24px;
      background-size: cover;
      // position: relative;
      // top: 0;
      // left: 0;
      // background-position: bottom;
      background-repeat: no-repeat;
      // background-size: 100%;
      background-position: top;
      background-position-x: 50%;
      position: relative;

        .lose-weight-heading {
          position: absolute;
          top: 72%;
          left: 6%;
        }

      @media screen and (min-width: $on-medium) {
        padding: 70px 24px;
        margin-bottom: 0px;
        background:
          linear-gradient(90deg, rgba(54, 68, 58, 0.00) 50%, #36443A 100%),
          url('https://d1cbtwb0qnacuk.cloudfront.net/images/james/james_with_tote_bag.avif');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 50%;
        height: auto;

        .lose-weight-heading {
          position: unset;
        }
      }

      p {
        margin: 0;
        color: $ivoryColor;
        font-size: 34px;
        line-height: 41px;
        @include font-larken(400);

        @media screen and (min-width: $on-medium) {
          padding-left: 45px;
          font-size: 42px;
        }
      }
    }

    .text-section {
      @media screen and (min-width: $on-medium) {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px 0;
      }
    }

    .rounded-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      gap: 100px;
      top: -18px;

      .text-content {
        margin-top: 15px;
        color: #A7C194;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        @include font-poppins(400);

        p {
          margin: 0;
        }
      }

      .rounded-div {
        border-radius: 100px;
        height: 96px;
        width: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid #A7C194;

        p {
          margin: 0;
          color: #A7C194;
          text-align: center;
          font-size: 15px;
          line-height: 19px;
          @include font-poppins(600);
        }
      }
    }

    .lose-weight-review {
      color: #FFFBF5;
      text-align: center;
      font-size: 12px;
      line-height: 23px;
    }
  }

  .section-weight-loss {
    background: url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/BentoColourShowcase_Green_130.jpeg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 685px;
    display: flex;
    align-items: center;

    @media screen and (min-width: $on-medium) {
      background: url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/BentoColourShowcase_Green_130_h.jpeg);
      height: 576px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-x: 0px;
    }

    .container {
      height: 277px;
      background: #36443A;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 45px;
      padding-right: 45px;

      @media screen and (min-width: $on-medium) {
        width: 100%;
      }

      .title {
        color: #FFEFE8;
        text-align: center;
        font-size: 34px;
        line-height: 41px;
        @include font-larken(400);
      }

      .footer-text {
        color: #FFEFE8;
        text-align: center;
        font-size: 12px;
        line-height: 120%;
        text-decoration-line: underline;
        @include font-poppins(600);
      }
    }
  }

  .section-join-movement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 14px;

    .title {
      color: #36443A;
      text-align: center;
      font-size: 34px;
      line-height: 41px;
      margin-top: 50px;
      @include font-larken(400);

      @media screen and (min-width: $on-medium) {
        font-size: 42px;
      }
    }

    .row-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 33px;

      @media screen and (min-width: $on-medium) {
        justify-content: center;
        gap: 53px;
      }

      .stats-container {
        p {
          margin: 0;
        }

        .title {
          color: #36443A;
          text-align: center;
          font-size: 22px;
          line-height: 23px;
          text-transform: uppercase;
          @include font-poppins(600);
        }

        .number-style {
          color: #36443A;
          text-align: center;
          font-size: 48px;
          line-height: 56px;
          @include font-poppins(400);
        }

        .value-style {
          color: #36443A;
          text-align: center;
          font-size: 24px;
          line-height: 27px;
          @include font-poppins(400);
        }
      }
    }

    .lose-weight-review {
      color: #36443A;
      text-align: center;
      font-size: 12px;
      line-height: 23px;
    }
  }

  .section-why-started {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 62px;
    margin-top: 10px;

    @media screen and (min-width: $on-medium) {
      display: none;
    }

    p {
      margin: 0;
    }

    .profile {
      height: 160px;
      width: 160px;
      border-radius: 100px;
      margin-bottom: 23px;
      margin-top: 10px;
    }

    .title {
      color: #36443A;
      text-align: center;
      font-size: $fs-xl;
      line-height: 41px;
      @include font-larken(400);
    }

    .name {
      color: #36443A;
      text-align: center;
      font-size: 22px;
      line-height: 23px;
      margin-bottom: 12px;
      @include font-larken(400);
    }

    .position {
      color: #36443A;
      font-size: 18px;
      line-height: 23px;
      @include font-larken(400);
    }

    .about-text {
      margin-top: 15px;
      color: #36443A;
      font-size: 12px;
      line-height: 23px;
      padding-left: 40px;
      padding-right: 40px;
      @include font-poppins(200);
    }
  }

  .section-why-started-desktop {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 62px;
    margin-top: 57px;

    @media screen and (min-width: $on-palm) {
      display: none;
    }

    @media screen and (min-width: $on-medium) {
      display: flex;
      flex-direction: row;
      padding-bottom: 80px;
      width: 80%;
      max-width: 1000px;
      margin: 0 auto;
    }

    .text-section {
      @media screen and (min-width: $on-medium) {
        width: 60%;
      }
    }

    .image-section {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    p {
      margin: 0;
    }

    .profile {
      height: 291px;
      width: 291px;
      border-radius: 150px;
      margin-bottom: 23px;
      margin-top: 10px;
    }

    .title {
      color: #36443A;
      font-size: $fs-xxl;
      line-height: 41px;
      @include font-larken(400);
    }

    .name {
      color: #36443A;
      text-align: center;
      font-size: 22px;
      line-height: 23px;
      text-align: center;
      margin-bottom: 12px;
      @include font-larken(400);
    }

    .position {
      color: #36443A;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      @include font-larken(400);
    }

    .about-text {
      margin-top: 15px;
      color: #36443A;
      font-size: $fs-sm;
      line-height: 23px;
      width: 90%;
      @include font-poppins(200);
    }

    .order-now-btn {
      justify-content: flex-start;
      display: flex;
      margin-top: 26px;
    }
  }

  .section-socialmedia {
    background: url(https://d1cbtwb0qnacuk.cloudfront.net/images/home/social-media-footer.jpeg);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 797px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: inset 0px -108px 220px 0px #000;

    @media screen and (min-width: $on-medium) {
      background-size: 55%;
      background-position-x: left;
      background-position-y: center;
      box-shadow: none;
      background-color: #36443A;
    }

    .section-text-content {
      @media screen and (min-width: $on-medium) {
        align-self: flex-end;
        width: 45%;
        height: 70%;
        gap: 10px;
        display: flex;
        flex-direction: column;
      }

      @media screen and (min-width: $on-large) {
        align-self: flex-end;
        width: 45%;
        height: 65%;
      }
    }

    .title {
      color: #FFF;
      text-align: center;
      font-size: 34px;
      line-height: 41px;
      @include font-larken(400);

      @media screen and (min-width: $on-medium) {
        font-size: 42px;
      }
    }

    .description {
      color: #FFF;
      text-align: center;
      font-size: 17px;
      line-height: 23px;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 10px;
      margin-top: 20px;
      @include font-poppins(400);
    }

    .email-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-right: 25px;

      @media #{$mq-max-md} {
        margin-right: unset;
      }
    }

    .email-input {
      display: flex;
      width: 45%;
      padding: 10px 16px;
      align-items: center;
      column-gap: 10px;
      font-size: 16px;
      @include font-poppins(400);
    }

    .sm-icon-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 42px;

      @media screen and (min-width: $on-medium) {
        justify-content: center;
        gap: 50px;
        align-items: center;
      }
    }
  }
}
