// Placeholder to allow overriding predefined variables smoothly.

// Colors 
$font-color: #36443A !default;
$darkColor: #36443A;
$creamColor: #FFFBF5;
$ivoryColor: #FFEFE8;
$coralColor: rgb(239, 146, 129);
$frostedMintColor: #F9FCFB;
$midGreenColor: #9EC293;
$midLightGreenColor: #C2D3C1;
$lightGreenColor: #C9D5C8;

// Fonts size
$fs-xs: 12px;
$fs-sm: 15px;
$fs-base: 16px;
$fs-md: 25px;
$fs-lg: 28px;
$fs-2x: $fs-base * 2;
$fs-xl: 34px;
$fs-xxl: 42px;
$fs-3x: $fs-base * 3;
$fs-5x: $fs-base * 5;

// Header height
$header-height-desktop: 130px;
$header-height-mobile: 95px;

// Spacing
$spacing-half: 10px;
$spacing-base: 20px;
$spacing-2x: $spacing-base * 2;
$spacing-3x: $spacing-base * 3;


// Breakpoints
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 1024px; 
$screen-xl: 1280px; 

// Media queries
$mq-max-md: "screen and (max-width: #{$screen-md})"; //@media #{$mq-max-md} {}
$mq-max-lg: "screen and (max-width: #{$screen-lg})"; //@media #{$mq-max-lg} {}

$mq-min-md: "screen and (min-width: #{$screen-md})"; //@media #{$mq-min-md} {}
$mq-min-lg: "screen and (min-width: #{$screen-lg})"; //@media #{$mq-min-lg} {}
$mq-min-xl: "screen and (min-width: #{$screen-xl})"; //@media #{$mq-min-xl} {}


